import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import rightarrow from '../rightarrow.png';
import axios from 'axios';
import { Domain, audience, urlapi } from '../../auth/Configuration';
import leftarrowback from '../leftarrowback.png';
import Spinner from 'react-bootstrap/Spinner';

import DataTable from 'datatables.net';
import $ from 'jquery';
import Collapse from 'react-bootstrap/Collapse';

const CompluanceTable = () => {
  const [openData, setOpenData] = useState(false);

  // // dev
  // var urlapi = "https://duvalfunctionapp.azureedge.net/api/duval";

  // stag

  // var urlapi = "https://duvalstag.azureedge.net/api/duval";

  const {
    user,

    getIdTokenClaims,
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
    accessToken,
    logout
  } = useAuth0();
  const [token, setToken] = useState();
  const [loader, setLoader] = useState();

  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) {}
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          setToken(accessidToken.__raw);
          let token = accessidToken.__raw;
          //    formData.token =
          //    sessionStorage.setItem("token",formData.token )

          application(token);
        } else {
        }
      }
    } catch (e) {}
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      getUserMetadata();
    }, 900000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getUserMetadata();
  }, []);

  const [users, setUsers] = useState();
  const application = async (token) => {
    setLoader(true);

    if (isAuthenticated) {
      // if(token !== "" && token !== null && token !== "undefined" && token !== undefined){

      const applicationdata12 = await axios
        .get(`${urlapi}admin/inprogress`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((applicationdata12) => {
          setLoader(false);

          setUsers(applicationdata12.data.reverse());
        });
      // }
    }
  };
  $.fn.dataTable.ext.errMode = 'none';

  useEffect(() => {
    $(document).ready(function () {
      $('#table_id').DataTable({
        scrollX: true,
        sScrollXInner: '100%'
      });
    });
  }, [openData]);

  return (
    <>
      <h4
        onClick={() => {
          setOpenData(!openData);
        }}
        aria-controls="example-collapse-text1_compliance_c1"
        aria-expanded={openData}
        className="drop_icon Completed_apllicatin_icon"
      >
        IN PROGRESS
      </h4>

      {/* <div
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
      >
        click
      </div> */}

      {loader === true ? (
        <div className="note-main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <Collapse in={openData}>
            <div id="example-collapse-text1_compliance_c1 mb-4">
              <table id="table_id" className="responsive nowrap" width="100%">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Date Started</th>
                    <th>Entity Name</th>
                    <th>$ Amount</th>
                    <th>Stage</th>
                    <th>Primary Contact Name</th>
                  </tr>
                </thead>
                <tbody>
                  {users !== undefined &&
                    users !== 'undefined' &&
                    users.map((user) => {
                      return (
                        <tr>
                          <td>
                            <div className="action">
                              {user.investmentType == 'Company' ? (
                                <span>
                                  <Link
                                    to={{
                                      pathname: `/ApplicationDetails/${user.id}`,
                                      state: user.id
                                    }}
                                  >
                                    <i
                                      title="View application details"
                                      class="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>
                                  </Link>
                                </span>
                              ) : (
                                <span>
                                  <Link
                                    to={{
                                      pathname: `/ApplicationDetailsIndi/${user.id}`,
                                      state: user.id
                                    }}
                                  >
                                    <i
                                      title="View application details"
                                      class="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>
                                  </Link>
                                </span>
                              )}

                              <span>
                                <Link to={{ pathname: `/History/${user.id}` }}>
                                  <i title="History" class="fa fa-history" aria-hidden="true"></i>
                                </Link>
                              </span>
                            </div>
                          </td>
                          <td>{user.createdAt}</td>

                          <td>
                            {user.entityName}
                            {/* {user.firstName !== null && user.firstName !== '' ? user.firstName : ''}{' '}
                          {user.lastName !== null && user.lastName !== '' ? user.lastName : ''} */}
                          </td>
                          <td>
                            {/* {userr.totalInvestment} */}
                            {user && user.totalInvestment != null ? <>$</> : ''}

                            {/* {user && user.totalInvestment != null
                              ? (Math.round(user.totalInvestment * 1000) / 1000).toLocaleString()
                              : ''} */}

                            {user && user.totalInvestment != null ? user.totalInvestment : ''}
                          </td>
                          <td>{user.applicationStage}</td>
                          <td>
                            {' '}
                            {user && user.firstName !== null && user.firstName !== ''
                              ? user.firstName
                              : ''}{' '}
                            {user && user.lastName !== null && user.lastName !== ''
                              ? user.lastName
                              : ''}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </Collapse>
        </>
      )}
    </>
  );
};

export default CompluanceTable;
