import React,{useState , useEffect} from 'react';

import Router from './routes';
import Route2 from './routes2';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import "./styles.css";
import "./main.css";

// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import { useAuth0 } from "@auth0/auth0-react";

// ----------------------------------------------------------------------

export default function App() {
  const { user, isAuthenticated, isLoading , logout } = useAuth0();
   
useEffect (()=>{
  {isAuthenticated && (user.profile == "User") && (
    logout()
   
    )}

})
  if (isLoading) {
    return <div className='main-loader'>

    <div className="loader"></div>
        </div>;
  }
  return (
    <>
    {isAuthenticated && (user.profile != "User") && (
      
      <ThemeConfig>
      
      <GlobalStyles />
      <BaseOptionChartStyle />
      <ScrollToTop />
      <Router />
    </ThemeConfig>
      )}

{!isAuthenticated && (
      
         
    
      <Route2 />
   
      )}
      </>
    
  );
}
