import { disableCache, Icon } from '@iconify/react';
import { useAuth0 } from '@auth0/auth0-react';
import React, { Component } from 'react';
import chevronRightFill from '@iconify/icons-eva/chevron-right-fill';
// ----------------------------------------------------------------------
const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

export const sidebarConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: getIcon(chevronRightFill)
  },
  {
    title: 'Applications',
    path: '/application',
    icon: getIcon(chevronRightFill)
  },
  {
    title: 'Clients',
    path: '/client',
    icon: getIcon(chevronRightFill)
  }
];
export const sidebarConfigu4 = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: getIcon(chevronRightFill)
  },
  {
    title: 'Applications',
    path: '/application',
    icon: getIcon(chevronRightFill)
  }
];
