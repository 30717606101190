import { Box, Grid, Container, Typography } from '@mui/material';
import React, { Component } from 'react';
import Page from '../components/Page';
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from '../../src/auth/LoginButton';

// import ScrollToTop from 'src/components/ScrollToTop';
import ScrollToTop from '../../src/components/ScrollToTop';

import Dashboard1 from './signatures/Dashboard1';

export default function DashboardApp() {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <>
      <LoginButton />

      {isAuthenticated && (
        <>
          {/* <LogoutButton/> */}
          {/* <Submit/> */}
          <ScrollToTop />
          {/* <Director /> */}
          <Dashboard1 />
          {/* <MultiStepForm/> */}

          {/* <MenuSidebar/> */}
          {/* <MenuSidebar/> */}
        </>
      )}
    </>
  );
}
