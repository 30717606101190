import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import rightarrow from '../rightarrow.png';
import axios from 'axios';
import { Domain, audience, urlapi } from '../../auth/Configuration';
import Director from './AllApplication';
import { da } from 'date-fns/locale';

const Dashboard1 = () => {
  const {
    getIdTokenClaims,
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
    accessToken,
    user,
    logout
  } = useAuth0();

  const [token, setToken] = useState();

  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) {}
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          setToken(accessidToken.__raw);
          let token = accessidToken.__raw;
          //    formData.token =
          //    sessionStorage.setItem("token",formData.token )

          result(token);
        } else {
        }
      }
    } catch (e) {}
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      getUserMetadata();
    }, 900000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getUserMetadata();
  }, []);

  const { pathname } = useLocation();

  const [data, setData] = useState([]);
  const [loader, setLoader] = useState();

  const result = async (token) => {
    setLoader(true);
    const applicationdata12 = await axios
      .get(`${urlapi}admin/deshboard`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        setData(res.data);
        setLoader(false);
      });
  };

  // useEffect(() => {
  //   result();
  // }, []);

  return (
    <>
      {loader === true ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <div className="right_content">
            <div className="heading__top ht_heading">
              <h2>
                {/* Dashboard */}
                DASHBOARD
              </h2>
            </div>
            <div className="form ">
              <div className="buttond_dashboard myfarm_form">
                {/* {user.profile == 'UR1' || user.profile == 'UR8' ? (
                  <div className="col-lg-6  col-sm-12 col-12 mb-sm-2 mb-lg-0">
                    <Link to="/">
                      <button>START NEW APPLICATION</button>
                    </Link>
                  </div>
                ) : null} */}

                {/* {user.profile == 'UR1' ||
                user.profile == 'UR2' ||
                user.profile == 'UR8' ||
                user.profile == 'UR9' ? ( */}
                <>
                  {/* {data &&
                      data.map((val, index) => (
                        <div className="col-lg-3  col-sm-12 col-12 my-farm-ad" key={index}>
                          {' '}
                          <button>
                            <h5>${val.total_investement}</h5>
                            <p>{val.application_status}</p>
                          </button>
                        </div>
                      ))} */}
                  <div className="col-lg-3  col-sm-12 col-12 my-farm-ad">
                    {' '}
                    <button>
                      <h5>
                        {data.applicationStage !== undefined
                          ? data.applicationStage
                          : data.applicationStage}{' '}
                      </h5>
                      <p>Total Applications Submitted</p>
                    </button>
                  </div>
                  <div className="col-lg-3 col-sm-12 col-12 my-farm-ad">
                    <button>
                      <h5>
                        ${data.totalAmount !== undefined ? data.totalAmount : data.totalAmount}
                      </h5>

                      <p>Total Applications $ Submitted</p>
                    </button>
                  </div>
                  <div className="col-lg-3 col-sm-12 col-12 my-farm-ad">
                    <button>
                      <h5>${data.AMLfail !== undefined ? data.AMLfail : data.AMLfail} </h5>
                      <p>Total Applications $ Pending AML</p>
                    </button>
                  </div>
                  <div className="col-lg-3 col-sm-12 col-12 my-farm-ad">
                    <button>
                      <h5>${data.amlPass !== undefined ? data.amlPass : data.amlPass} </h5>
                      <p>Total Applications $ Passed</p>
                    </button>
                  </div>
                </>

                {/* {user.profile == 'UR3' ? (
                  <div className=" col-lg-6  col-sm-7 col-12">
                    <Link to="/application">
                      <button>APPLICATIONS</button>
                    </Link>
                  </div>
                ) : null} */}

                {/* {user.profile == 'UR4' ? (
                  <div className=" col-lg-6  col-sm-12 col-12">
                    {' '}
                    <Link to="/application">
                      <button>Applications – Approval & Payments</button>
                    </Link>
                  </div>
                ) : null} */}

                {/* {user.profile == 'UR4' ? (
                  <div className="col-lg-6 col-sm-12 col-12">
                    <Link to="/client">
                      <button>CLIENTS</button>
                    </Link>
                  </div>
                ) : (
                  ''
                )} */}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Dashboard1;
