import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import React, { Component } from 'react';
//
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import Blog from './pages/Blog';
import User from './pages/User';
import ApplicationDetails from './pages/signatures/ApplicationDetails';
import ApplicationDetailsIndi from './pages/signatures/ApplicationDetailsIndi';
import CompanyDeatils from './pages/signatures/CompanyDeatils';
import AdherenceAgreement from './pages/signatures/AdherenceAgreement';
import SubscriptionAgreement from './pages/signatures/SubscriptionAgreement';
import AllApplication from './pages/signatures/AllApplication';
import Client from './pages/signatures/Client';
import Clients from './pages/signatures/Clients';
import Dashboard1 from './pages/signatures/Dashboard1';

import History from './pages/signatures/History';
// import ClinetDetails from './pages/signatures/ClinetDetails';

// ----------------------------------------------------------------------
//SubscriptionAgreementCom
export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: 'applicationdetails/:id', element: <ApplicationDetails /> },
        { path: 'applicationdetailsindi/:id', element: <ApplicationDetailsIndi /> },
        { path: 'Companydeatils/:id', element: <CompanyDeatils /> },
        { path: 'Clients/:id', element: <Clients /> },
        { path: 'SubscriptionAgreement/:id', element: <SubscriptionAgreement /> },
        { path: 'application', element: <AllApplication /> },
        // { path: 'ClinetDetails/:id', element: <ClinetDetails /> },

        { path: 'client', element: <Client /> },
        { path: 'Dashboard1', element: <Dashboard1 /> },
        { path: 'History/:id', element: <History /> },
        { path: 'dashboard', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> }
      ]
    },

    { path: '*', element: <Navigate to="/dashboard" /> }
  ]);
}
