import leftarrowback from '../leftarrowback.png';
import logo from '../logo.png';
// import $ from 'jquery';

import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { Domain, audience, urlapi } from 'src/auth/Configuration';

import SemanticDatepicker from 'react-semantic-ui-datepickers';
import swal from 'sweetalert';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import SignaturePad from 'react-signature-pad-wrapper';
import $ from 'jquery';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const SubscriptionAgreement = ({ formData, props }) => {
  const mySignatureRef = useRef(null);
  const mySignatureRef1 = useRef(null);
  const navigate = useNavigate();
  const {
    user,

    getIdTokenClaims,
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
    accessToken,
    logout
  } = useAuth0();

  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
  const [token, setToken] = useState();
  const [loader, setLoader] = useState();

  const Clear = (e) => {
    // $('#printDate').text(val);
    // formData.certificateA_Date = val;

    const setValues = localStorage.getItem('setValues');
    let signatureC = mySignatureRef.current.clear();

    // formData.signatureC = signatureC;
    //    let data = {
    //     columnName: 'documentProof',
    //     fileName:"documentProof",
    //     document:signatureA
    //   };
  };
  const { id } = useParams();

  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) {}
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          setToken(accessidToken.__raw);
          let token = accessidToken.__raw;
          //    formData.token =
          //    sessionStorage.setItem("token",formData.token )

          application(token);
          setTokenn(token);
        } else {
        }
      }
    } catch (e) {}
  };
  const [user1, setUser1] = useState();
  const [tokenn, setTokenn] = useState();
  const [user2, setUser2] = useState();
  const [directodata, setDirectodata] = useState();
  const [subscriptionSign, setSubscriptionSign] = useState();

  const application = async (token) => {
    setLoader(true);

    if (isAuthenticated) {
      // if(token !== "" && token !== null && token !== "undefined" && token !== undefined){

      const applicationdata12 = await axios
        .get(`${urlapi}/admin/user/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((applicationdata12) => {
          setLoader(false);
          applicationdiretor(token);
          // setUser1(applicationdata12.data[0]);

          if (applicationdata12.data[0].investmentType == 'Company') {
            var object = applicationdata12.data[0].personal;
            var idd = parseInt(id);
            var tes = object.find((tst) => tst.id === idd);
            setUser1(tes);
          } else {
            if (applicationdata12.data[0].investmentType == 'Individual') {
              setUser1(applicationdata12.data[0]);
            }
          }

          if (applicationdata12.data.length > 1) {
            setUser2(applicationdata12.data[1]);
          }
        });
    }
  };
  const applicationdiretor = async (token) => {
    if (isAuthenticated) {
      // if(token !== "" && token !== null && token !== "undefined" && token !== undefined){

      const applicationdata12 = await axios
        .get(`${urlapi}/admin/director/approvals/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((applicationdata12) => {
          setLoader(false);
          setDirectodata(applicationdata12.data);
        });
      // }
    }
  };
  const hndClick = () => {
    let signatureA1 = mySignatureRef.current.toDataURL();

    let isEmpty = mySignatureRef.current.isEmpty();

    if (signatureA1 === null || signatureA1 === '' || isEmpty) {
      Swal.fire({
        text: 'Please Sign'
      });
    } else {
      applicationdiretorPost(signatureA1);
    }
  };

  const hndClick1 = () => {
    let signatureA1 = mySignatureRef1.current.toDataURL();

    let isEmpty = mySignatureRef1.current.isEmpty();

    if (signatureA1 === null || signatureA1 === '' || isEmpty) {
      Swal.fire({
        text: 'Please Sign'
      });
    } else {
      applicationdiretorPost(signatureA1);
    }
  };

  const [director1, setDirector1] = useState();
  const [director2, setDirector2] = useState();
  const [directore3, setDirector3] = useState();
  const [director3, setDirector4] = useState();

  const applicationdiretorPost = async (signatureA1) => {
    setLoader(true);

    let dePost = {
      subscriptionSign: true,
      adherenceSign: true
    };

    if (isAuthenticated) {
      // if(token !== "" && token !== null && token !== "undefined" && token !== undefined){

      const applicationdata12 = await axios
        .post(`${urlapi}/admin/director/approvals/${id}`, dePost, {
          headers: { Authorization: `Bearer ${tokenn}` }
        })
        .then((applicationdata12) => {
          setLoader(false);
          applicationdiretor(tokenn);
        });
      // }
    }
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      getUserMetadata();
    }, 900000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getUserMetadata();
  }, []);

  const Clear1 = (e) => {
    var date = new Date();
    var val = date.getDate() + '/' + (date.getMonth() + 4) + '/' + date.getFullYear();
    $('#printDate').text(val);
    // formData.certificateA_Date = val;

    const setValues = localStorage.getItem('setValues');
    let signatureA = mySignatureRef1.current.clear();

    // formData.signatureC = signatureC;
    //    let data = {
    //     columnName: 'documentProof',
    //     fileName:"documentProof",
    //     document:signatureA
    //   };
  };
  return (
    <>
      {loader === true ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="right_content ">
          <div className="heading__top ht_heading">
            <h2>Director Dashboard</h2>
          </div>

          <div className="form director">
            <div className="back_btn">
              {/* <Link to="/application">
                <img src={leftarrowback} />
              </Link> */}
            </div>

            <div className="wrapper_director">
              <div className="heading__top ht_heading inline_block_main">
                <h4 className="DIRECTORE__H">
                  {/* <span onClick={() => navigate(-1)}> */}
                  <Link
                    // to="/application"
                    to={{
                      pathname: `/ApplicationDetails/${id}`,
                      state: id
                    }}
                  >
                    <i class="fa fa-arrow-left ICON__Arrow" aria-hidden="true"></i>
                  </Link>
                  {/* </span> */}
                  {}
                  Sign subscription agreement For :
                  {user1 && user1.investmentType === 'Joint' ? (
                    <>
                      <p className="entityename__dirctore">{user2 && (user2.entityName ?? '')}</p>
                    </>
                  ) : (
                    <>
                      <p className="entityename__dirctore">{user1 && (user1.entityName ?? '')}</p>
                    </>
                  )}
                </h4>
              </div>

              <div className="red_green_text button_changes_layout">
                {directodata !== undefined &&
                  directodata !== 'undefined' &&
                  directodata.map((userr) => {
                    return (
                      <>
                        {userr.isLogin === true ? (
                          <>
                            <>
                              {userr.subscriptionSign == null || userr.subscriptionSign == '' ? (
                                <div className="signed">
                                  <button
                                    className="dirctore_button_red"
                                    onClick={() => {
                                      applicationdiretorPost();
                                    }}
                                  >
                                    Sign All Documents Now
                                  </button>
                                </div>
                              ) : (
                                <>
                                  <div className="signed">
                                    <button className="dirctore_button_green">
                                      Successfully Signed
                                    </button>
                                  </div>
                                </>
                              )}
                            </>
                          </>
                        ) : (
                          ''
                        )}
                      </>
                    );
                  })}
              </div>
            </div>

            <div className=" terms_cond_main">
              <div className="Blackbg-logo">
                <div className="main-wrapper-term">
                  <div className="logoBlack">
                    <img src={logo} alt="TermsLOgo" className="TermsLOgo"></img>
                  </div>
                  <div className="input-date">
                    <div className="d-flex">
                      <label className="colorChanges">Dated:</label>
                      <label id="printDate" className="colorChanges">
                        {user1 &&
                        user1.certificateDetails.subscriptionSignCreatedAt !== null &&
                        user1.certificateDetails.subscriptionSignCreatedAt !== undefined &&
                        user1.certificateDetails.subscriptionSignCreatedAt !== 'undefined'
                          ? user1.certificateDetails.subscriptionSignCreatedAt
                          : ''}{' '}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="Subscription-fund">
                  <p>
                    SUBSCRIPTION AGREEMENT
                    <br /> <span>Mortgage Fund</span>
                  </p>
                </div>
              </div>
              <div className="table-box">
                <table class="table table-borderless table-term-condition">
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td className="widthTD">
                        <p>Between</p>
                      </td>
                      <td className="widthTD-2">
                        <p>Du Val Mortgage Fund Limited Partnership(FSP1000594)</p>
                      </td>
                      <td className="widthTD-3">
                        <p>(Fund)</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="widthTD">
                        <p>And</p>
                      </td>
                      <td className="widthTD-2">
                        <p>Du Val Capital Partners Limited(6277i59)</p>
                      </td>
                      <td className="widthTD-3">
                        <p>(General Partner or GP)</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="widthTD">
                        <p>And</p>
                      </td>
                      <td className="widthTD-2">
                        <p>Du Val Group Limited(5986215) </p>
                      </td>
                      <td className="widthTD-3">
                        <p>(Intial Limited Partner)</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="widthTD">
                        <p>And</p>
                      </td>
                      <td className="widthTD-2">
                        <p>
                          {user1 && user1.investmentType === 'Joint' ? (
                            <>
                              <p>{user2 && (user2.entityName ?? '')}</p>
                            </>
                          ) : (
                            <>
                              <p>{user1 && (user1.entityName ?? '')}</p>
                            </>
                          )}
                        </p>
                      </td>
                      <td className="widthTD-3">
                        <p>Investor</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="main-second-text-content">
                <p className="list-header">BACKGROUND</p>
                <ol type="A" className="ORDER-Content">
                  <li>
                    The Fund is seeking to raise capital that the Fund will loan to members of the
                    Du Val Affiliates to assist those members with the acquisition of developments
                    sites and construction of property developments on those sites.
                  </li>
                  <li>The Investor wishes to invest in the Fund.</li>
                  <li>
                    The General Partner is permitted by the Du Val Mortgage Fund Limited Partnership
                    Agreement and has been delivered to the Investor (the Partnership Agreement) to
                    seek investment in the Fund.
                  </li>
                  <li>
                    The Investor wishes to invest the sum of
                    <strong>
                      {' '}
                      ${user1 && user1.totalInvestment !== null ? user1.totalInvestment : ''}{' '}
                    </strong>
                    (Investment Sum).
                  </li>
                  <li>
                    The Initial Limited Partner holds partnership units on trust, on behalf of
                    future investors. Both the General Partner and the Initial Limited Partner are
                    permitted, pursuant to the Agreement, to issue an Investor with a stake in the
                    Fund in consideration for its Investment Sum.
                  </li>
                  <li>
                    Upon payment and receipt of the Investor’s Investment Sum, the General Partner
                    will issue the Investor units in the Fund as allocated according to clause 1.3.
                  </li>
                </ol>
                <ol className="list-one ORDER-Content">
                  <p className="list-header">THE PARTIES AGREE</p>
                  <li class="order-list-item">
                    <b className="bold-bottom-space">SUBSCRIPTION</b>
                    <ol>
                      <li className="sub-list-item">
                        The General Partner is seeking subscriptions for investment in the Fund for
                        a total sum of $100,000,000 (Subscription Sum). The percentage of the total
                        units in the Fund that will be distributed to the new investors in the Fund
                        will represent 100% of the total units in the Fund (Subscription Units).
                      </li>
                      <li>
                        The Initial Limited Partner originally held 100% of the Subscription Units
                        on behalf of future limited partners, which includes the Investor, and that
                        on completion of the investment process the Initial Limited Partner will
                        allow the General Partner to distribute to the Investor that Investor’s
                        portion of the Subscription Units.
                      </li>
                      <li>
                        The Investor will be distributed one Fund unit for every dollar of that
                        Investor’s Investment Sum (Investor Units).
                      </li>
                    </ol>
                  </li>
                  <li>
                    <b className="bold-bottom-space">SETTLEMENT</b>
                    <ol className="ORDER-Content">
                      <li>
                        The Investor will, upon the GP’s notice of the Investor’s eligibility to
                        subscribe to Subscription Units, provide to the GP the following:
                        <br />
                        <span class="bottom-space">
                          (a) a duly completed AML compliance form; and
                        </span>
                        <br />
                        <span class="bottom-space"> (b) signed adherence agreement.</span>
                      </li>
                      <li>
                        Once the GP receives and approves of the forms under clauses 2.1(a) and (b),
                        the GP will within 5 workings days provide the Investor confirmation, or
                        otherwise, of that Investor’s entry onto the Fund’s Register as a holder of
                        Investor Units pending that Investor’s payment to the GP of the Investment
                        Sum
                      </li>
                      <li>
                        The General Partner will upon payment of the Investment Sum, made by the
                        Investor within 5 working days of the General Partner’s notice under clause
                        2.2:
                        <br />
                        <span class="bottom-space">
                          {' '}
                          (a) grant to the Investor the Investor’s Units; and
                        </span>
                        <br />
                        <span class="bottom-space">
                          (b) enter the name of the Investor in the Register, as the registered
                          holder of the Investor’s Units.
                        </span>
                      </li>
                    </ol>
                    <li>
                      The Investor agrees that the rights and obligations that apply to the Units it
                      will receive under this Agreement will be those set out in the Partnership
                      Agreement.
                    </li>
                    <li>
                      Upon the completion of the steps set out at clauses 2.1-2.3, the Initial
                      Limited Partner will accept a dilution of its partnership units in the value
                      of the Investor Units.
                    </li>
                  </li>
                  <li>
                    <b className="bold-bottom-space"> PARTNERSHIP REPRESENTATIONS AND WARRANTIES</b>
                    <p className="list-header">
                      The General Partner warrants and represents to the Investor that:
                    </p>
                    <ol>
                      <li>
                        Following deposit to the Fund’s account, the Investment Sum will be held, on
                        trust, in the General Partner’s account, subject to any drawdowns,
                        redemptions and other payments permitted under the Partnership Agreement.
                      </li>
                      <li>
                        The Investment Sum will be used for the purposes of lending to a Du Val
                        Affiliate (Borrower) for:
                        <br />
                        <span class="bottom-space">
                          (a) an acquisition of a site by the Borrower for development;
                        </span>
                        <br />
                        <span class="bottom-space">
                          (b) funding a residential property development by the Borrower; or
                        </span>
                        <br />
                        <span class="bottom-space">
                          {' '}
                          (c) as expressly provided under the terms of the Partnership Agreement.
                        </span>
                      </li>
                      <li>
                        No Investment Sum will be advanced to a Borrower unless the General Partner
                        has entered into suitable security agreements.
                      </li>
                      <li>
                        When lending Partnership Funds to a Borrower, the General Partner will
                        endeavour to secure repayment of those Partnership funds by:
                      </li>
                      <div className="space-bottom-wrapper">
                        <span class="bottom-space padding-left-term">
                          {' '}
                          (a) a registered mortgage over the site owned by the Borrower (and to
                          which the lending of Partnership Funds is specifically made for);
                        </span>
                        <br />
                        <span class="bottom-space">
                          {' '}
                          (b) assignments from the Borrower for the key contracts for that
                          Borrower’s development, including pre-sale sale and purchase agreements
                          with purchasers of units in the development; or{' '}
                        </span>
                        <br />
                        <span class="bottom-space">
                          {' '}
                          (c) such other security, guarantees or other similar arrangements as may
                          be available in the circumstances to secure the Borrower’s obligations to
                          the Partnership
                        </span>
                      </div>
                    </ol>
                  </li>
                  <li>
                    <b className="bold-bottom-space">PAYMENT</b>
                    <ol>
                      <li>
                        The Investor will, subject to clause 2.2 of this agreement, pay the full
                        amount of their Investment Sum to the Fund’s GP, Du Val Capital Partners
                        Limited, using the details set out below:
                      </li>
                      <br />
                      <div className="wrapper-Partners">
                        <p className="bold-Partners">Du Val Capital Partners Limited</p>
                        <p>Account Number: TBA</p>
                      </div>
                    </ol>
                  </li>
                  <li>
                    <b className="bold-bottom-space">WARRANTY</b>
                    <ol>
                      <li>
                        {' '}
                        The Investor warrants to the Fund that it is a wholesale investor or
                        eligible investor pursuant to Schedule One of the Financial Markets Conduct
                        Act 2013
                      </li>
                      <li>
                        The Investor indemnifies and holds harmless the Fund against any loss, cost,
                        claim, damage, expense or liability suffered or incurred at any time by the
                        Fund as a direct or indirect result of the warranties and representations in
                        clause 5.1 being incorrect.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <b className="bold-bottom-space">CONDITION</b>
                    <ol>
                      <li>
                        Settlement (means the issue of Units to the Investors) shall be subject to,
                        and conditional on, the General Partner obtaining, and being satisfied with,
                        any additional information or documentation the Fund may reasonably require
                        in connection with the Investor’s warranties and representations by the
                        settlement date. This condition is for the sole benefit of the Fund and may
                        be waived by the Fund at any time in its sole discretion
                      </li>
                      <li>
                        The GP reserves the right to decline any application by Hong Kong and
                        Singapore based investors for Units in the Fund if the investment thresholds
                        in the Overseas Investment (Urgent Measures) Amendment Act 2020 have been or
                        may be reached.
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
              <div class="main-signature-content1 ">
                <p>EXECUTED AS AN AGREEMENT:</p>

                <div class="upper-space-table">
                  <table class="behalf-sign border">
                    <tr>
                      <td class="term-Signed border">Signed for and on behalf of</td>
                      <td class="term-entity border">
                        {' '}
                        {user1 && user1.fullLegalName !== null ? user1.fullLegalName : ''}{' '}
                      </td>
                      <td class="term-investor border">as Investor:</td>
                    </tr>
                  </table>
                </div>

                <div className="row director_dash fullinput">
                  <div className="col-md-8">
                    <div className="input__fild">
                      <img
                        src={
                          user1 &&
                          user1.certificateDetails !== null &&
                          user1.certificateDetails.subscriptionSign
                            ? user1.certificateDetails.subscriptionSign
                            : ''
                        }
                      />
                    </div>
                  </div>
                </div>

                {user2 && user2.fullLegalName !== null ? (
                  <div class="upper-space-table">
                    <table class="behalf-sign border">
                      <tr>
                        <td class="term-Signed border">Signed for and on behalf of</td>
                        <td class="term-entity border">
                          {' '}
                          {user2 && user2.fullLegalName !== null ? user2.fullLegalName : ''}{' '}
                        </td>
                        <td class="term-investor border">as Investor:</td>
                      </tr>
                    </table>
                  </div>
                ) : null}

                <div className="row director_dash fullinput">
                  <div className="col-md-8">
                    <div className="input__fild">
                      <img
                        src={
                          user2 &&
                          user2.certificateDetails !== null &&
                          user2.certificateDetails.subscriptionSign
                            ? user2.certificateDetails.subscriptionSign
                            : ''
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h3 className="SIGNED__Directors">
              SIGNED for and on behalf of DU VAL CAPITAL PARNERS LIMITED as General Partner by its
              Directors:
              {/* MORTGAGE FUND LIMITED by its Directors of its
              General Partners */}
            </h3>
            {directodata !== undefined &&
              directodata !== 'undefined' &&
              directodata.map((userr) => {
                return (
                  <>
                    {userr.isLogin == true ? (
                      <>
                        {userr.section === '2' ? (
                          <div className="director pt-4">
                            <div className="row director_dash fullinput">
                              <div className="col-md-4">
                                <div className="input__fild">
                                  <label>Date</label>
                                  {date}
                                </div>
                              </div>

                              <div className="col-md-4">
                                <label>FULL NAME OF AUTHORISED PERSON</label>
                                <p>{userr.name}</p>
                              </div>

                              <div className="col-md-4">
                                <label>OFFICE HELD</label>
                                <p>Director</p>
                              </div>

                              <div className="col-md-4">
                                <img
                                  src={userr.subscriptionSign}
                                  alt="subscriptionSign3"
                                  className="mb-4"
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      <>
                        {userr.section === '2' ? (
                          <div className="director pt-4">
                            <div className="row director_dash fullinput">
                              <div className="col-md-4">
                                <div className="input__fild">
                                  <label>Date</label>
                                  {date}
                                </div>
                              </div>

                              <div className="col-md-4">
                                <label>FULL NAME OF AUTHORISED PERSON</label>
                                <p>{userr.name}</p>
                              </div>

                              <div className="col-md-4">
                                <label>OFFICE HELD</label>
                                <p>Director</p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    )}
                  </>
                );
              })}

            <h3 className="SIGNED__Directors">
              {/* SIGNED for and on behalf of DU VAL GROUP NZ LIMITED as Initial Limited Partner by its
              Directors: */}
              SIGNED for and on behalf of DU VAL MORTGAGE FUND LIMITED PARNERSHIP by the Directors
              of its General Partner:
            </h3>
            {directodata !== undefined &&
              directodata !== 'undefined' &&
              directodata.map((userr) => {
                return (
                  <>
                    {userr.isLogin == true ? (
                      <>
                        {userr.section === '2' ? (
                          <div className="director pt-4">
                            <div className="row director_dash fullinput">
                              <div className="col-md-4">
                                <div className="input__fild">
                                  <label>Date</label>
                                  {date}
                                </div>
                              </div>

                              <div className="col-md-4">
                                <label>FULL NAME OF AUTHORISED PERSON</label>
                                <p>{userr.name}</p>
                              </div>

                              <div className="col-md-4">
                                <label>OFFICE HELD</label>
                                <p>Director</p>
                              </div>

                              <div className="col-md-4">
                                <img
                                  src={userr.subscriptionSign}
                                  alt="subscriptionSign3"
                                  className="mb-4"
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      <>
                        {userr.section === '2' ? (
                          <div className="director pt-4">
                            <div className="row director_dash fullinput">
                              <div className="col-md-4">
                                <div className="input__fild">
                                  <label>Date</label>
                                  {date}
                                </div>
                              </div>

                              <div className="col-md-4">
                                <label>FULL NAME OF AUTHORISED PERSON</label>
                                <p>{userr.name}</p>
                              </div>

                              <div className="col-md-4">
                                <label>OFFICE HELD</label>
                                <p>Director</p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    )}
                  </>
                );
              })}

            <h3 className="SIGNED__Directors">
              SIGNED for and on behalf of DU VAL GROUP NZ LIMITED as Initial Limited Partner by its
              Directors:
              {/* SIGNED for and on behalf of DU VAL MORTGAGE FUND LIMITED PARTNERSHIP by of its General */}
            </h3>
            {directodata !== undefined &&
              directodata !== 'undefined' &&
              directodata.map((userr) => {
                return (
                  <>
                    {userr.section === '1' ? (
                      <div className="director pt-4">
                        <div className="row director_dash fullinput">
                          <div className="col-md-4">
                            <div className="input__fild">
                              <label>Date</label>
                              {date}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <label>FULL NAME OF AUTHORISED PERSON</label>
                            <p>{userr.name}</p>
                          </div>

                          <div className="col-md-4">
                            <label>OFFICE HELD</label>
                            <p>Director</p>
                          </div>

                          {userr.isLogin == true ? (
                            <div className="col-md-4">
                              <img src={userr.subscriptionSign} />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                );
              })}

            {/* ------------------------------------------ Adhrance -------------------- */}

            <div className="">
              <div className="">
                <div className=""></div>

                <div className=" terms_cond_main magin-space">
                  <div className="Blackbg-logo">
                    <div className="main-wrapper-term">
                      <div className="logoBlack">
                        <img src={logo} alt="TermsLOgo" className="TermsLOgo"></img>
                      </div>
                      <div className="input-date">
                        <div className="d-flex">
                          <label className="colorChanges">Dated:</label>
                          <label id="printDate" className="colorChanges">
                            {user1 && user1.certificateDetails.adherenceSignCreatedAt !== null
                              ? user1.certificateDetails.adherenceSignCreatedAt
                              : ''}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="Subscription-fund">
                      <p>
                        ADHERENCE AGREEMENT
                        <br /> <span>Mortgage Fund</span>
                      </p>
                    </div>
                  </div>
                  <div className="table-box">
                    <table class="table table-borderless table-term-condition">
                      <thead></thead>
                      <tbody>
                        <tr>
                          <td className="widthTD">
                            <p>Between</p>
                          </td>
                          <td className="widthTD-2">
                            <p>Du Val Mortgage Fund Limited Partnership(FSP1000594)</p>
                          </td>
                          <td className="widthTD-3">
                            <p>(Fund)</p>
                          </td>
                        </tr>
                        <tr>
                          <td className="widthTD">
                            <p>And</p>
                          </td>
                          <td className="widthTD-2">
                            <p>Du Val Capital Partners Limited(6277i59)</p>
                          </td>
                          <td className="widthTD-3">
                            <p>(General Partner or GP)</p>
                          </td>
                        </tr>
                        <tr>
                          <td className="widthTD">
                            <p>And</p>
                          </td>
                          <td className="widthTD-2">
                            <p>Du Val Group Limited(5986215) </p>
                          </td>
                          <td className="widthTD-3">
                            <p>(Intial Limited Partner)</p>
                          </td>
                        </tr>
                        <tr>
                          <td className="widthTD">
                            <p>And</p>
                          </td>
                          <td className="widthTD-2">
                            {user1 && user1.investmentType === 'Joint' ? (
                              <>{user2 && (user2.entityName ?? '')}</>
                            ) : (
                              <>{user1 && (user1.entityName ?? '')}</>
                            )}
                          </td>
                          <td className="widthTD-3">
                            <p>Investor</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="main-second-text-content">
                    <p className="list-header">RECITAL</p>
                    <ul className="ORDER-Content">
                      <p>
                        A. From{' '}
                        <span className="date_c">
                          {user1 && user1.certificateDetails.adherenceSignCreatedAt !== null
                            ? user1.certificateDetails.adherenceSignCreatedAt
                            : ''}{' '}
                        </span>{' '}
                        <strong>(the Effective Date)</strong> the New Partner is intending to become
                        a Limited Partner (as defined in the) Limited Partnership Agreement dated
                        23rd July 2020 and made between the General Partner and the Initial Partner{' '}
                        <strong>(the Partnership Agreement)</strong>
                      </p>
                      <p>
                        B. The Parties have agreed to enter into this Agreement pursuant to the
                        terms of the Partnership Agreement.
                      </p>
                    </ul>
                    <p class="list-header">OPERATIVE PART</p>
                    <ol className="list-one ORDER-Content">
                      <li class="order-list-item">
                        <b className="bold-bottom-space">CONSIDERATION</b>
                      </li>
                      <ol>
                        <li className="sub-list-item">
                          In consideration of, and subject to the New Partner being admitted as a
                          Limited Partner (as defined in the Partnership Agreement) from the
                          Effective Date:
                        </li>
                        <div className="list_abc">
                          <span>
                            (a) The New Partner agrees that the New Partner is to have the benefit
                            and obligations of and be bound by the terms of the Partnership
                            Agreement as if the New Partner were an initial Limited Partner.
                          </span>
                          <br />

                          <span>
                            (b) The Parties agree that the New Partner is to have the benefit of and
                            be bound by the terms and conditions of the Partnership Agreement as if
                            the New Partner were named as a Limited Partner (as defined in the
                            Partnership Agreement).
                          </span>
                          <br />
                          <span>
                            (c) This Agreement is governed in all respects by the laws of New
                            Zealand and each of the Parties irrevocably submits to the non-exclusive
                            jurisdiction of the New Zealand Courts about any claim, dispute or
                            matter arising out or relating to this Agreement.
                          </span>
                        </div>
                      </ol>
                      <br />
                      <li>
                        <b className="bold-bottom-space">
                          NEW PARTNER CONTACT INFORMATION AND REGISTER INSTRUCTIONS
                        </b>
                        <table class="PARTNER-info">
                          <tbody>
                            <tr>
                              <td class="PARTNER-info-content">New Partner Name/Entity</td>
                              <td class="PARTNER-info-content-second">
                                {' '}
                                {user1 && user1.investmentType === 'Joint' ? (
                                  <>{user2 && (user2.entityName ?? '')}</>
                                ) : (
                                  <>{user1 && (user1.entityName ?? '')}</>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td class="PARTNER-info-content">New Partner Contact Number</td>
                              <td class="PARTNER-info-content-second">
                                {' '}
                                {user1 ? user1.priContactNumber : ''}
                              </td>
                            </tr>
                            <tr>
                              <td class="PARTNER-info-content">New Partner Contact Email</td>
                              <td class="PARTNER-info-content-second">
                                <p>{user1 ? user1.userEmail : ''}</p>
                              </td>
                            </tr>
                            <tr>
                              <td class="PARTNER-info-content">
                                New Partner Nominated Bank Account
                              </td>
                              <td class="PARTNER-info-content-second">
                                {user1 ? user1.primaryBankAccountNumber : ''}
                                <br />
                                {user2 ? user2.primaryBankAccountNumber : ''}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                    </ol>
                  </div>

                  <div class="main-signature-content1 mt-4 pt-2">
                    <p>EXECUTED AS AN AGREEMENT:</p>

                    <div class="upper-space-table">
                      <table class="behalf-sign border">
                        <tr>
                          <td class="term-Signed border">Signed for and on behalf of</td>
                          <td class="term-entity border">
                            {' '}
                            {user1 && user1.fullLegalName != null && user1.fullLegalName
                              ? user1.fullLegalName
                              : ''}
                          </td>
                          <td class="term-investor border">as Investor:</td>
                        </tr>
                      </table>
                    </div>

                    <div className="row director_dash fullinput">
                      <div className="col-md-8">
                        <div className="input__fild">
                          <img
                            src={
                              user1 &&
                              user1.certificateDetails !== null &&
                              user1.certificateDetails.adherenceSign
                                ? user1.certificateDetails.adherenceSign
                                : ''
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div class="upper-space-table mt-5">
                      <table class="behalf-sign border">
                        <tr>
                          <td class="term-Signed border">Signed for and on behalf of</td>
                          <td class="term-entity border">
                            {' '}
                            {user2 && user2.fullLegalName != null && user2.fullLegalName
                              ? user2.fullLegalName
                              : ''}
                          </td>
                          <td class="term-investor border">as Investor:</td>
                        </tr>
                      </table>
                    </div>

                    <div className="row director_dash fullinput">
                      <div className="col-md-8">
                        <div className="input__fild">
                          <img
                            src={
                              user2 &&
                              user2.certificateDetails !== null &&
                              user2.certificateDetails.adherenceSign
                                ? user2.certificateDetails.adherenceSign
                                : ''
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <h3 className="SIGNED__Directors">
                  SIGNED for and on behalf of Du Val Mortgage Fund Limited Partnership by Du Val
                  Capital Partners as General Partner by two of its Directors:
                </h3>
                {directodata !== undefined &&
                  directodata !== 'undefined' &&
                  directodata.map((userr) => {
                    return (
                      <>
                        {userr.isLogin == true ? (
                          <>
                            {userr.section === '2' ? (
                              <div className="director pt-4">
                                <div className="row director_dash fullinput">
                                  <div className="col-md-4">
                                    <div className="input__fild">
                                      <label>Date</label>
                                      {date}
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <label>FULL NAME OF AUTHORISED PERSON</label>
                                    <p>{userr.name}</p>
                                  </div>

                                  <div className="col-md-4">
                                    <label>OFFICE HELD</label>
                                    <p>Director</p>
                                  </div>

                                  <div className="col-md-4">
                                    <img
                                      src={userr.adherenceSign}
                                      alt="adherenceSign"
                                      className="mb-4"
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                          </>
                        ) : (
                          <>
                            {userr.section === '2' ? (
                              <div className="director pt-4">
                                <div className="row director_dash fullinput">
                                  <div className="col-md-4">
                                    <div className="input__fild">
                                      <label>Date</label>
                                      {date}
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <label>FULL NAME OF AUTHORISED PERSON</label>
                                    <p>{userr.name}</p>
                                  </div>

                                  <div className="col-md-4">
                                    <label>OFFICE HELD</label>
                                    <p>Director</p>
                                  </div>

                                  <div className="col-md-4"></div>
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                          </>
                        )}
                      </>
                    );
                  })}

                <h3 className="SIGNED__Directors">
                  SIGNED for and on behalf of Du Val Group NZ Limited as the Initial Partner by two
                  of its Directors:
                </h3>
                {directodata !== undefined &&
                  directodata !== 'undefined' &&
                  directodata.map((userr) => {
                    return (
                      <>
                        {userr.section === '1' ? (
                          <div className="director pt-4">
                            <div className="row director_dash fullinput">
                              <div className="col-md-4">
                                <div className="input__fild">
                                  <label>Date</label>
                                  {date}
                                </div>
                              </div>

                              <div className="col-md-4">
                                <label>FULL NAME OF AUTHORISED PERSON</label>
                                <p>{userr.name}</p>
                              </div>

                              <div className="col-md-4">
                                <label>OFFICE HELD</label>
                                <p>Director</p>
                              </div>
                              {userr.isLogin == true ? (
                                <>
                                  <div className="col-md-4">
                                    {' '}
                                    <img src={userr.adherenceSign} />
                                  </div>
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SubscriptionAgreement;
