import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import rightarrow from '../rightarrow.png';
import axios from 'axios';
import { Domain, audience, urlapi } from '../../auth/Configuration';
import leftarrowback from '../leftarrowback.png';
import Spinner from 'react-bootstrap/Spinner';
import Pagination from 'react-bootstrap/Pagination';
import DataTable from 'datatables.net';
import $ from 'jquery';
import Collapse from 'react-bootstrap/Collapse';

const Client = () => {
  const [open, setOpen] = useState(true);

  // // dev
  // var urlapi = "https://duvalfunctionapp.azureedge.net/api/duval";

  // stag

  // var urlapi = "https://duvalstag.azureedge.net/api/duval";

  const {
    user,

    getIdTokenClaims,
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
    accessToken,
    logout
  } = useAuth0();
  const [token, setToken] = useState();
  const [loader, setLoader] = useState();

  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) {}
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          setToken(accessidToken.__raw);
          let token = accessidToken.__raw;
          //    formData.token =
          //    sessionStorage.setItem("token",formData.token )

          application(token);
        } else {
        }
      }
    } catch (e) {}
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      getUserMetadata();
    }, 900000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getUserMetadata();
  }, []);

  const [users, setUsers] = useState();
  const application = async (token) => {
    // setLoader(true);

    if (isAuthenticated) {
      // if(token !== "" && token !== null && token !== "undefined" && token !== undefined){
      // duval/admin/clients
      const applicationdata12 = await axios
        .get(`${urlapi}/dev/admin/clients`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((res) => {
          if (res.status == 200) {
            setLoader(false);

            setUsers(res.data);
          } else {
            setLoader(false);
          }
        });
      // }
    }
  };
  // alert(user.profile)

  $.fn.dataTable.ext.errMode = 'none';

  $(document).ready(function () {
    $('#History_table').DataTable({
      scrollX: true,
      sScrollXInner: '100%'
    });
  });

  return (
    <>
      {loader === true ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="right_content">
          <div className="heading__top ht_heading">
            <h2>
              <Link to="/Dashboard1">
                <i class="fa fa-arrow-left ICON__Arrow" aria-hidden="true"></i>
              </Link>
              CLIENTS
              {/* Clients */}
            </h2>
          </div>

          <div className="form director">
            {/* {user.profile == 'UR2' ? <CompluanceTable /> : null} */}

            {/* {user.profile == 'UR1' ||
            user.profile == 'UR2' ||
            user.profile == 'UR8' ||
            user.profile == 'UR4' ||
            user.profile == 'UR9' ? ( */}
            <>
              <h4
                onClick={() => setOpen(!open)}
                aria-controls="example-collapse-text1"
                aria-expanded={open}
                className="drop_icon"
              >
                Active Clients
              </h4>
              <Collapse in={open}>
                <div id="example-collapse-text1">
                  <table id="History_table" class="responsive nowrap" width="100%">
                    <thead>
                      <tr>
                        <th>Action</th>

                        <th>Entity Name</th>

                        <th>Primary Contact Name</th>

                        <th>Primary Contact Number</th>

                        <th>Active Client Date</th>
                        <th>Application Approved Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users !== undefined &&
                        users !== 'undefined' &&
                        users.reverse().map((userr) => {
                          return (
                            <tr>
                              <td>
                                <div className="action">
                                  <span>
                                    <Link
                                      to={{
                                        pathname: `/Clients/${userr.id}`,
                                        state: userr.id
                                      }}
                                    >
                                      <i
                                        title="View client details"
                                        class="fa fa-eye hover__Edit"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                  </span>

                                  {user.profile == 'UR4' ? null : (
                                    <Link to={{ pathname: `/History/${userr.id}` }}>
                                      <i
                                        title="History"
                                        class="fa fa-history"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                  )}
                                </div>
                              </td>
                              {/* <td>{user.firstName +" "+ user.lastName}</td> */}

                              <td>{userr && userr.entityName ? userr.entityName : ''}</td>
                              <td>
                                {userr && userr.firstName !== null && userr.firstName !== ''
                                  ? userr.firstName
                                  : ''}{' '}
                                {userr && userr.lastName !== null && userr.lastName !== ''
                                  ? userr.lastName
                                  : ''}
                              </td>
                              <td>{userr.priContactNumber}</td>

                              <td>{userr && userr.activeAt ? userr.activeAt : ''}</td>
                              <td>{userr && userr.approvedAt ? userr.approvedAt : ''}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </Collapse>
            </>
            {/* ) : null} */}
          </div>
        </div>
      )}
    </>
  );
};

export default Client;
