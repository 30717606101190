import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import leftarrowback from '../leftarrowback.png';
import axios from 'axios';
import { Domain, audience, urlapi } from '../../auth/Configuration';
import Tabs from 'react-bootstrap/Tabs';
import { Row, Col, Nav, Tab, Table } from 'react-bootstrap';
import logo from '../logo.png';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Collapse from 'react-bootstrap/Collapse';
import DataTable from 'datatables.net';
import $ from 'jquery';

const History = () => {
  //  // dev
  //  var urlapi = "https://duvalfunctionapp.azureedge.net/api/duval";

  // stag

  //  var urlapi = "https://duvalstag.azureedge.net/api/duval";

  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    user,

    getIdTokenClaims,
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
    accessToken,
    logout
  } = useAuth0();
  const [token, setToken] = useState();
  const [loader, setLoader] = useState();

  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) {}
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          setToken(accessidToken.__raw);
          let token = accessidToken.__raw;
          //    formData.token =
          //    sessionStorage.setItem("token",formData.token )

          application(token);
        } else {
        }
      }
    } catch (e) {}
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      getUserMetadata();
    }, 900000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getUserMetadata();
  }, []);

  const [user12, setUser12] = useState();
  const [userH, setUserH] = useState([{}]);
  const application = async (token) => {
    setLoader(true);
    // alert("llllllll")

    if (isAuthenticated) {
      // if(token !== "" && token !== null && token !== "undefined" && token !== undefined){
      const applicationdata12 = await axios
        .get(`${urlapi}admin/user/history/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((applicationdata12) => {
          setLoader(false);
          setUser12(applicationdata12.data);
          setUserH(applicationdata12.data);
        });
      // }
    }
  };

  $.fn.dataTable.ext.errMode = 'none';

  $(document).ready(function () {
    $('#history_table_client').DataTable({
      scrollX: true,
      sScrollXInner: '100%'
    });
  });

  return (
    <>
      {loader === true ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="right_content">
          <div className="heading__top ht_heading">
            <h2>
              <span onClick={() => navigate(-1)}>
                <i class="fa fa-arrow-left ICON__Arrow" aria-hidden="true"></i>
              </span>
              HISTORY
              {/* <button onClick={() => navigate(-1)}>Go back</button> */}
            </h2>
          </div>

          <div className="form   director">
            <div className="">
              <div className="">
                <div className="entity_name">
                  <h5>{userH ? userH[0].entityName : ''}</h5>
                </div>

                <Table id="history_table_client" class="responsive nowrap" width="100%">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th> Email</th>

                      <th>Action</th>
                      <th>User Name</th>

                      <th>Previous Data</th>
                      <th>New Data</th>
                      <th>Notes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {user12 &&
                      user12
                        .slice(0)
                        .reverse()
                        .map((user11) => {
                          return (
                            <tr>
                              <td>{user11.createdAt}</td>
                              <td>{user11.actionBy}</td>
                              <td>{user11.action}</td>
                              <td>{user11.fullLegalName}</td>
                              <td>{user11.previousData}</td>
                              <td>{user11.newData}</td>
                              <td>{user11.notes}</td>
                            </tr>
                          );
                        })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default History;
