// // my firm retails
export const Domain = 'dev-r914xhfs.au.auth0.com';
export const clientId = 'EUNRusS6mkyGqSpjxGRAeZK69e5RK6tB';
export const audience = 'https://dev-r914xhfs.au.auth0.com/api/v2/';
export const CHECK_ENV = 'dev';
export const connection = 'myfarm-retail';
export const singup_auth = 'https://dev-r914xhfs.au.auth0.com/dbconnections/signup';
export const urlapi = 'https://awlf2m9k8a.execute-api.ap-southeast-2.amazonaws.com/dev/';
export const adminapi = 'https://awlf2m9k8a.execute-api.ap-southeast-2.amazonaws.com';

// my firm stage
// export const Domain = 'myfarm-nz.au.auth0.com';
// export const clientId = 'xSI39Ti8kVIsV8Ss9afrxpyTCRH4YJhG';
// export const audience = 'https://myfarm-nz.au.auth0.com/api/v2/';
// export const CHECK_ENV = 'stag';
// export const connection = 'my-farm-nz';
// export const singup_auth = 'https://myfarm-nz.au.auth0.com/dbconnections/signup';
// export const urlapi = 'https://dk8daj5mgp5cf.cloudfront.net/';
// export const adminapi = 'https://dk8daj5mgp5cf.cloudfront.net/';

// my firm prod
// export const Domain = 'myfarmretail.au.auth0.com';
// export const clientId = 'cI9XxqZX9SQNxLfcGXq3507UE7V7tGBm';
// export const audience = 'https://myfarmretail.au.auth0.com/api/v2/';
// export const CHECK_ENV = 'prod';
// export const connection = 'myfarm-retails';
// export const singup_auth = 'https://myfarmretail.au.auth0.com/dbconnections/signup';
// export const urlapi = 'https://dun87s03p8666.cloudfront.net/';
// export const adminapi = 'https://dun87s03p8666.cloudfront.net/';
