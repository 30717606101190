import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import React, { Component } from 'react';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import Register2 from './pages/Register2';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import Blog from './pages/Blog';
import User from './pages/User';
import NotFound from './pages/Page404';
import Profile from './auth/Profile';

// ----------------------------------------------------------------------

export default function Route2() {
  return useRoutes([
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'register2', element: <Register2 /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/register" /> },
        { path: '*', element: <Navigate to="/" /> }
      ]
    }
    // { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
