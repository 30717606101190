import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import rightarrow from '../rightarrow.png';
import axios from 'axios';
import { Domain, audience, urlapi } from '../../auth/Configuration';
import leftarrowback from '../leftarrowback.png';
import Spinner from 'react-bootstrap/Spinner';
import CompluanceTable from './CompluanceTable';
import Pagination from 'react-bootstrap/Pagination';
import Collapse from 'react-bootstrap/Collapse';
import DataTable from 'datatables.net';
import $ from 'jquery';
import SubscriptionAgreement from './SubscriptionAgreement';
import PassedApplication from './PassedApplication';
import PendingAml from './PendingAml';

const AllApplication = () => {
  const [directodata, setDirectodata] = useState();

  const [open, setOpen] = useState(true);

  // // dev
  // var urlapi = "https://duvalfunctionapp.azureedge.net/api/duval";

  // stag

  // var urlapi = "https://duvalstag.azureedge.net/api/duval";

  const {
    user,

    getIdTokenClaims,
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
    accessToken,
    logout
  } = useAuth0();
  const [token, setToken] = useState();
  const [loader, setLoader] = useState();

  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) {}
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          setToken(accessidToken.__raw);
          let token = accessidToken.__raw;
          //    formData.token =
          //    sessionStorage.setItem("token",formData.token )

          application(token);
        } else {
        }
      }
    } catch (e) {}
  };
  useEffect(() => {
    getUserMetadata();
  }, []);

  const [users, setUsers] = useState();
  const application = async (token) => {
    setLoader(true);

    //   if (isAuthenticated) {
    //     // if(token !== "" && token !== null && token !== "undefined" && token !== undefined){

    //     const applicationdata12 = await axios
    //       .get(`${urlapi}/dev/admin/duval/aml/Pending`, {
    //         headers: { Authorization: `Bearer ${token}` }
    //       })
    //       .then((applicationdata12) => {
    //         setLoader(false);

    //         setUsers(applicationdata12.data.reverse());
    //       });
    //     // }
    //   }
    // };
  };
  // alert(user.profile)
  $.fn.dataTable.ext.errMode = 'none';

  $(document).ready(function () {
    $('#table_id').DataTable({
      scrollX: true,
      sScrollXInner: '100%'
    });
  });

  return (
    <>
      <div className="right_content">
        <div className="heading__top ht_heading">
          <h2>
            <Link to="/Dashboard1">
              <i class="fa fa-arrow-left ICON__Arrow" aria-hidden="true"></i>
            </Link>
            APPLICATIONS
          </h2>
        </div>
        <div className="form director">
          {/* {user.profile == 'UR2' ? <CompluanceTable /> : null} */}
          {/* 
          {user.profile == 'UR1' ||
          user.profile == 'UR8' ||
          user.profile == 'UR2' ||
          user.profile == 'UR4' ||
          user.profile == 'UR3' ||
          user.profile == 'UR9' ? ( */}

          <div className="myfarm_com" key={1}>
            {' '}
            <CompluanceTable />
          </div>
          <div className="myfarm_com" key={2}>
            {' '}
            <PendingAml />
          </div>

          <div className="myfarm_com" key={3}>
            {' '}
            <PassedApplication />
          </div>

          {/* ) : null} */}
        </div>
      </div>
    </>
  );
};

export default AllApplication;
